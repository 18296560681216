import { useEffect } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// ---------------------------------------------------------

import Icon from '@components/icon';
import Link from '@components/link';
import Image from '@components/image';

// ---------------------------------------------------------

import {
  realtor_card_horizontal_large,
  realtor_card_horizontal_small,
  realtor_card_office_detail,
  realtor_card_primary,
  bg_container,
  bg_overlay,
  realtor_img,
  inner_content,
  realtor_name,
  realtor_team,
  realtor_subtitle,
  phone_number,
  realtor_licenses,
  contact_content,
  contact_link,
  container,
  full_width,
  on_listing_page,
  realtor_languages,
  card
} from './styles.module.scss';

const themeOptions = {
  horizontalLarge: realtor_card_horizontal_large,
  horizontalSmall: realtor_card_horizontal_small,
  officeDetail: realtor_card_office_detail,
  primary: realtor_card_primary
};

// ---------------------------------------------------------

const RealtorCard = ({
  theme,
  imageDimensions,
  hasTour,
  contact,
  onListingPage,
  setModalIsOpen,
  showLicensedIn
}) => {
  const {
    displayPrimary,
    displaySecondary,
    displayPhone,
    displayEmail,
    displayWebsiteUrl,
    professionalTitle,
    displayPhotoUrl,
    designations,
    isOhioLicensed,
    languages,
    isKentuckyLicensed,
    isIndianaLicensed
  } = contact || {};

  const router = useRouter();

  const containerClasses = classNames(container, {
    [full_width]: onListingPage
  });

  const classes = classNames({
    [on_listing_page]: onListingPage,
    [themeOptions[theme]]: themeOptions[theme]
  });

  useEffect(() => {
    if (hasTour && router?.isReady) {
      setModalIsOpen(!!router?.query?.tourModal);
    }
  }, [router]);

  const licenseString = () => {
    const licenses = [];
    if (isOhioLicensed) licenses.push('OH');
    if (isKentuckyLicensed) licenses.push('KY');
    if (isIndianaLicensed) licenses.push('IN');
    return licenses.length ? `Licensed in ${licenses.join(', ')}` : '';
  };

  const isLargeTheme = theme === 'horizontalLarge' || theme === 'officeDetail';

  const contactLinks = (
    <div className={contact_content}>
      {onListingPage ? (
        <>
          {displayPhone && (
            <span className={contact_link}>
              <Link url={`tel:${displayPhone}`}>
                <Icon name="phone" type="actions" />
                Call
              </Link>
            </span>
          )}
          {displayPhone && (
            <span className={contact_link}>
              <Link url={`sms:${displayPhone}`}>
                <Icon name="text" type="actions" />
                Text
              </Link>
            </span>
          )}
          {hasTour ? (
            <span className={contact_link}>
              <button onClick={() => setModalIsOpen(true)}>
                <Icon type="general" name="clock" />
                Tour
              </button>
            </span>
          ) : (
            displayWebsiteUrl && (
              <span className={contact_link}>
                <Link url={displayWebsiteUrl}>
                  <Icon name="website" type="actions" />
                  Website
                </Link>
              </span>
            )
          )}
        </>
      ) : (
        <>
          {displayEmail && (
            <span className={contact_link}>
              <Link url={`mailto:${displayEmail}`}>
                <Icon name="email" type="actions" />
                Email
              </Link>
            </span>
          )}
          {displayPhone && (
            <span className={contact_link}>
              <Link url={`tel:${displayPhone}`}>
                <Icon name="phone" type="actions" />
                Call
              </Link>
            </span>
          )}
          {displayWebsiteUrl && (
            <span className={contact_link}>
              <Link url={displayWebsiteUrl}>
                <Icon name="website" type="actions" />
                Website
              </Link>
            </span>
          )}
        </>
      )}
    </div>
  );

  return (
    <div className={containerClasses}>
      <div className={classes}>
        <div className={bg_container}>
          <div className={bg_overlay}></div>
        </div>
        <div className={card}>
          <Link url={displayWebsiteUrl} className={realtor_img}>
            <Image
              image={{
                alt: `Headshot of ${displayPrimary}`,
                height: imageDimensions?.height ?? 125,
                src: displayPhotoUrl ? displayPhotoUrl : '/images/avatar_placeholder.jpeg',
                width: imageDimensions?.width ?? 165
              }}
              layout="responsive"
            />
          </Link>
          <div className={inner_content}>
            <Link url={displayWebsiteUrl}>
              <h2 className={realtor_name}>{displayPrimary}</h2>
            </Link>
            {displaySecondary && onListingPage ? (
              <h3 className={realtor_subtitle}>{displaySecondary}</h3>
            ) : (
              <div className={realtor_team}>{displaySecondary}</div>
            )}
            {!displaySecondary && professionalTitle && onListingPage && (
              <h3 className={realtor_subtitle}>{professionalTitle}</h3>
            )}

            {professionalTitle && !onListingPage && (
              <h3 className={realtor_subtitle}>{professionalTitle}</h3>
            )}

            {displayPhone && onListingPage && (
              <div className={phone_number}>
                <Link url={`tel:${displayPhone}`}>{displayPhone}</Link>
              </div>
            )}
            {designations && !onListingPage && (
              <div className={realtor_languages}>{designations}</div>
            )}
            {languages && <div className={realtor_languages}>Speaks {languages}</div>}
            {showLicensedIn && licenseString() && (
              <div className={realtor_licenses}>{licenseString()}</div>
            )}
          </div>
          {isLargeTheme && contactLinks}
        </div>
        {!isLargeTheme && contactLinks}
      </div>
    </div>
  );
};

RealtorCard.propTypes = {
  /**
   * Controls the layout of the realtor card.
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),

  /**
   * The dimensions of the image for the realtor.
   */
  imageDimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  }),

  /**
   * Specifies if the listing has a the request a tour
   */
  hasTour: PropTypes.bool,

  /**
   * The contact information for the realtor.
   */
  contact: PropTypes.shape({
    photoUrl: PropTypes.string,
    name: PropTypes.string,
    teamName: PropTypes.string,
    professionalTitle: PropTypes.string,
    languages: PropTypes.string,
    designations: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    websiteUrl: PropTypes.string,
    isOhioLicensed: PropTypes.bool,
    isKentuckyLicensed: PropTypes.bool,
    isIndianaLicensed: PropTypes.bool
  }),

  /**
   * Specifies if component renders on listing page (changes styles)
   */
  onListingPage: PropTypes.bool,

  /**
   * Function to open the request a tour modal
   */
  setModalIsOpen: PropTypes.func,

  /**
   * Specifies if the licensed in string should be shown
   */
  showLicensedIn: PropTypes.bool
};

RealtorCard.defaultProps = {
  theme: 'primary',
  contact: {}
};

export default RealtorCard;
